import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/nicholaswarner/dawnstar/MM-W/catalyst/node_modules/gatsby-theme-catalyst-core/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Seo = makeShortcode("Seo");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Seo title="About MudMuseum" mdxType="Seo" />
    <h1 {...{
      "id": "about-mudmuseum"
    }}>{`About MudMuseum`}</h1>
    <p>{`MudMuseum was a concept born out of a work sabbatical. While enjoying time off to rest and focus on health and
fitness, a need for maintaining and expanding my technical skills grew. The MudMuseum was something I’ve always
wanted but never articulated conceptually. `}<em parentName="p">{`Nostalgia`}</em>{` for the thousands of lost hours in `}<strong parentName="p">{`Rom`}</strong>{`, `}<strong parentName="p">{`Circle`}</strong>{`,
and `}<strong parentName="p">{`GodWars`}</strong>{` MUDs, as a player and as an owner, has grown into a need to share this rich history with others. `}</p>
    <p>{`To that end, MudMuseum was born.`}</p>
    <h2 {...{
      "id": "my-history-with-muds"
    }}>{`My History with MUDs`}</h2>
    <p>{`Starting out on a Circle MUD named Alternate Illusions in 1997, I rolled a Warrior and died. `}<em parentName="p">{`Cyclops`}</em>{`, my hardy
Warrior trudged on through the vast city of Midgaard. Over months, he grew in level, and somewhere in the mid
thirties, I rolled a Mage, `}<em parentName="p">{`JuanJr`}</em>{`. I wish I knew the backstory behind the name, perhaps I rolled Juan and died
out of the gate, but in either case, JuanJr began his career throwing Fireballs. `}</p>
    <p><img parentName="p" {...{
        "src": "../assets/circle.png",
        "alt": "CircleMud Placeholder"
      }}></img></p>
    <p>{`As the MUD loosened its rules and allowed multiplaying, I rolled a Cleric, Ninja, and several other classes
creating a strong trio of characters to venture out into the world. There were ten minute long battles against
named boss-enemies from pop-culture and historical themes. Roman and Greek Gods, Looney Toons characters, and
Marvel X-Men mutants all became fun adventures for loot and challenges.`}</p>
    <p><img parentName="p" {...{
        "src": "../assets/circle.png",
        "alt": "Image Placeholder"
      }}></img></p>
    <p>{`I soon moved on to several GodWars muds nearing the end of the 90s; `}<em parentName="p">{`Shadow Wars`}</em>{`, `}<em parentName="p">{`The Gathering`}</em>{`, and `}<em parentName="p">{`Dystopia`}</em>{`
were some of the muds which stood out. A Werewolf named `}<em parentName="p">{`Fang`}</em>{`, a Demon named `}<em parentName="p">{`Brock`}</em>{`, and a variety-of-classes named
`}<em parentName="p">{`Ailen`}</em>{`, I enjoyed the GodWars MUD genre training, venturing, questing, and killing players. This was during my
early teens and became a focal point for my maturity (or lack thereof!)`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "88.61111111111111%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAASCAYAAABb0P4QAAAACXBIWXMAABYlAAAWJQFJUiTwAAACHklEQVQ4y61U23ajMAzEV9mGcE1pSXP5/6/UjuTQnLTNy+4+6GCEGY1GYzdN0/B/jtcfU0rcdR3HSIioOZcchwFr07Dxho0zdW3NC8D7Rv3ZOc458zgU9t5rLn8UTmtmlz371msBS07X8m/zHUg2llPLjcW7MeyDZ4vqlIitsewPgeNEtSj2OIDZaJ9blo82WK0iEceoT9MYTpS4lKJMfQSj7LTNvd29mwcxAZMK9q6HFBDwLmj1WKBfiMpQclJ411Lip2R3dlJZ9QC4aCL5gPa0rYhBRBQoOzur0tw1ew4RfhxG7tqOvfMKEDFFAdNiZDlNiSlDQ2GFwjZ5pvWd6e2Nwzhy2jam4/GhoViDiGq7YODbwOk91xbBVqaalsyhr9bJh8xHAMzLwtM08bqu3A8Dhmd/2kZYpDlhMKQtuQI2C3ScCYBB93i0e71e+XK56PN2u+nQfvVhBhiNlYkMigCkUy9VM9UX+XmeK8tpVo/uxn8CjKB86goTdBX6MiTxnLT65Ybvh0Byz9apCwcTn8bCG/QhVJMB7Z6UAQnYbqvdu5qHQ+QpFnsYG7F0xJ9Dy1MGmA967OQnARSWwlb8qbZByyKB2kp0V6vZB+ABHvsYM28TTkSQSgYip7oZGwWUjunrBMlw1FbO/H7b9BR46wt/LgWtv7h9ZOK53jR6EdhXtxS0swgZBHm3e+nvo+97Lm3LGRdAi9NyPp//CfAPfmV5bKdoevsAAAAASUVORK5CYII=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/77ca4901336dde8709217e5483b19a91/a3012/dystopia.webp 360w", "/static/77ca4901336dde8709217e5483b19a91/bc514/dystopia.webp 720w", "/static/77ca4901336dde8709217e5483b19a91/b2d4b/dystopia.webp 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/77ca4901336dde8709217e5483b19a91/f21e7/dystopia.png 360w", "/static/77ca4901336dde8709217e5483b19a91/37523/dystopia.png 720w", "/static/77ca4901336dde8709217e5483b19a91/4ad3a/dystopia.png 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/77ca4901336dde8709217e5483b19a91/4ad3a/dystopia.png",
            "alt": "Dystopia",
            "title": "Dystopia",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Some time between 1998 and 2001, I ventured into a GodWars MUD called `}<em parentName="p">{`Battle of the Immortals`}</em>{`. This would end up
being the primary codebase that I used moving forward, having been a part of the development team before the MUD
shut down. It was also one of the best, most fun MUDs I’d ever played, with an all-star cast of developers working
to build a beautiful world to venture into.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1214px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "39.72222222222222%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAABYlAAAWJQFJUiTwAAABU0lEQVQoz01SR5LDMAzTpLhIbqquSQ7O+J7/Pw5LKBPPHmCJpAgSpFUwAVZbBOMRNBEQmwgv9qAHRIk77VCUBcqyRF3X0FqjKAo0TYO2bWGMyT7GVIwJ3gekOCKECOs8xhgxjSPmeRJfwLIsGMWO4l/XFdu2SY6X+IzH44FpmrI9DANU32u51PK4EadBSlJVCIy1qKSDRqqzm8vlknG/33G73fKdHVdVleME/craVggNnPue1hq00lXvHDqpSDlVVedkY75SeafE6/V6jkEp9QUlO8ocpyyZoORVuqScn+R5jni/F8E7S6T8rusyIXESJgn85sFkPvwl0Kaf80spyX0Uwj0XIj6fD47jwL7vuWjf91BMpsFBk+RHSCKCMe+dkPLcsG6HLGYTVU5In3i9XueS2LHih6u3sgSeBH3sjuAMu05+jWZAVY9o2mcm43JOmf/wB38Ivyigw3hYAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/256a45799293e84f0fd9255c239a6577/a3012/fighting.webp 360w", "/static/256a45799293e84f0fd9255c239a6577/bc514/fighting.webp 720w", "/static/256a45799293e84f0fd9255c239a6577/874e8/fighting.webp 1214w"],
            "sizes": "(max-width: 1214px) 100vw, 1214px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/256a45799293e84f0fd9255c239a6577/f21e7/fighting.png 360w", "/static/256a45799293e84f0fd9255c239a6577/37523/fighting.png 720w", "/static/256a45799293e84f0fd9255c239a6577/f8836/fighting.png 1214w"],
            "sizes": "(max-width: 1214px) 100vw, 1214px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/256a45799293e84f0fd9255c239a6577/f8836/fighting.png",
            "alt": "Fighting text",
            "title": "Fighting text",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Outside of the MUDs themselves, I spent untold hours debating and enjoying conversation on The MudConnector (TMC)
forums. This was one of the biggest online MUD resources during the late 90s and early 2000s, hosting public
codebases, offering listings of hundreds if not thousands of (in)active muds, forums, and more. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1440px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.44444444444444%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAYAAACpUE5eAAAACXBIWXMAABYlAAAWJQFJUiTwAAACxElEQVQ4y42SyWsTYRiHJ9tMkplkJkmTtmZrEpqkJm1NlW4x0bZuuFew6l38A1QQLFbbxh0FLZ4E8eRBERQiqEhNXc+evHl3owcvKtrHL5Oilnpw4OH91t/7e9/5pLdv3/H50xc+vP/I/Pw8375953++nz++wsICTx4/4NDBYRZ+NNalmzfucPvWLI8ezjI3N0vtqYi1pzybq1GrzVKtVrlffc696msRX5ncM3nJwydvuHDpOjv3bOL23RdcvXYLqTWxhda2dSSy2winNhERpFbuINmx3RwrRgmrPobFu1+w7w/6fiRtjMGRo2zedZwtu48zeqAi1owsquZDcfmwWN1IVheSxSlwYZc9yEodt8CF4nSjKHVcv/F4NDTNjderoXs9SIlUN+EVQZyKjCRJv5EdNpoCPvx+A133oqr1Sx6TuoiJpom5V+zrZjTvBlvSyLJNTCyLSMtQVZVQKETAH8AwDHw+H4FAgGAwiM1mX3pe98eJhFtwudz/EGskiEQiJFNJ1vT10l8sMjA4QCaTFmRMx3+flcKxHLlcFrdbXSZoWYxWqx3VIRMWSaOqRiTUQjQaE21RlptoXpGhrS2K3eFYksmp9yD7d+Nt3Ui8o0Q8UybRLmKqRHu+TDI/QjxbFntDaM3b8bSMYleCjZKbQwHxx5yLgtZGmekDFIZmSPdOE0gdJjc4Sc/IOfKlabrLU/QMnSHTO0G2f5Jc8SJthfM43AnxDiMddHWuFE1uaghaGoLR9F66ShfoWlshmh8nveaUED1DrHOc9tWnyItxctUE5W1XiHZOYG06gk2JIXmMGIau4XDIS0qOZcZYPXyZVOEErdljdAtnhXVn6eiri02JZKcplCtsGJ0hnJugqf0kTk04lGxGo/E22xLBeHYfhfWXyfZNCQfjIk6KUs+RG5gmX6wIhHj/FMO7ZihuFS67KqbDX/YstMR1g8BFAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/477a1f39f53bec38416c278a2d20f328/a3012/tmc.webp 360w", "/static/477a1f39f53bec38416c278a2d20f328/bc514/tmc.webp 720w", "/static/477a1f39f53bec38416c278a2d20f328/f992d/tmc.webp 1440w", "/static/477a1f39f53bec38416c278a2d20f328/6d2c9/tmc.webp 2160w", "/static/477a1f39f53bec38416c278a2d20f328/c489f/tmc.webp 2312w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/477a1f39f53bec38416c278a2d20f328/f21e7/tmc.png 360w", "/static/477a1f39f53bec38416c278a2d20f328/37523/tmc.png 720w", "/static/477a1f39f53bec38416c278a2d20f328/07a9c/tmc.png 1440w", "/static/477a1f39f53bec38416c278a2d20f328/b3608/tmc.png 2160w", "/static/477a1f39f53bec38416c278a2d20f328/f5aa5/tmc.png 2312w"],
            "sizes": "(max-width: 1440px) 100vw, 1440px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/477a1f39f53bec38416c278a2d20f328/07a9c/tmc.png",
            "alt": "TMC The MudConnector",
            "title": "TMC The MudConnector",
            "loading": "lazy",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`Over my time spent playing MUDs, I’ve made hundreds of friends and enemies and it would be a disservice to forget
mentioning anyone. So I’ll just leave my thoughts at offering my deepest gratitude to everyone who played, and my
sincere wishes that they have had a fantastic life after MUDs.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      